import React from "react";
import { PieChart } from "react-minimal-pie-chart";

const Piechart = ({ output }) => {
  let data = [];
  console.log(output.output);

  let firstContri = output.output[0][1] / (output.output[0][1] + output.output[1][1] + output.output[2][1]);
  let thirdContri = output.output[2][1] / (output.output[0][1] + output.output[1][1] + output.output[2][1]);


  const filtered_output_array = output.output;

  const defaultLabelStyle = {
    fontSize: "4px",
    fill: "white",
    fontFamily: "sans-serif",
  };

  const allThreeData = [
    {
      title: filtered_output_array[0][0],
      value: filtered_output_array[0][1],
      color: "#2a2f4f",
    },
    {
      title: filtered_output_array[1][0],
      value: filtered_output_array[1][1],
      color: "#917fb3",
    },
    {
      title: filtered_output_array[2][0],
      value: filtered_output_array[2][1],
      color: "#e5beec",
    },
  ]

  const topTwoData = [
    {
      title: filtered_output_array[0][0],
      value: filtered_output_array[0][1],
      color: "#2a2f4f",
    },
    {
      title: filtered_output_array[1][0],
      value: filtered_output_array[1][1],
      color: "#917fb3",
    },
  ];

  const topOneData = [
    {
      title: filtered_output_array[0][0],
      value: filtered_output_array[0][1],
      color: "#2a2f4f",
    },
  ];
  data = allThreeData;

  if (firstContri > 0.99) {
    data = topOneData;
  }
  else if (thirdContri > 0.15) {
    data = topTwoData;
  }


  return (
    <PieChart
      labelStyle={{
        ...defaultLabelStyle,
      }}
      label={({ dataEntry }) => dataEntry.title}
      data={data}
    />
  );
};

export default Piechart;
